import React from 'react';
import style from './projectCard.module.less';

const ProjectCard = (props) => {
  const { data: { node } } = props;
  console.log(props)
  return (
    <div className={style.postCard}>
      <a href={node ? node.link : ''}>
        <div
          className={style.postCardImg}
          style={{
            backgroundImage: `url(${node ? node.cover.localFile.childImageSharp.fluid.src : ''})`,
          }}
        />
        <div className={style.mrTp20}>
          <h3>{node ? node.title : ''}</h3>
          <p>{node ? node.excerpt : ''}</p>
          <p style={{ color: '#ce6d96', wordSpacing: '10px' }}>
            {node.technologies.split(' ').map(t => 
                <span className={style.techHolder}>{t}</span>
            )}
          </p>
        </div>
      </a>
    </div>
  );
};

export default ProjectCard;
