import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Row, Col } from 'antd';
import Header from '../../components/PageLayout/Header';

import SidebarWrapper from '../../components/PageLayout/Sidebar';
import ProjectCard from '../../components/ProjectCard';
import SEO from '../../components/Seo';

const Projects = ({ data }) => (
  <Layout className="outerPadding">
    <Layout className="container">
      <Header />
      <SEO
        title="Projects"
        description="A showcase of various projects have done in my spare time and some for my great clients."
        path="projects"
      />
      <SidebarWrapper>
        <div className="marginTopTitle">
          <h1 className="titleSeparate">Projects</h1>
        </div>
        <Row gutter={[20, 20]}>
          {
            data.allStrapiProject && data.allStrapiProject.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={24} sm={24} md={12} lg={8}>
                <ProjectCard data={val} />
              </Col>
            ))
          }
        </Row>
      </SidebarWrapper>
    </Layout>
  </Layout>
);


export const query = graphql`
  query {
    allStrapiProject {
      edges {
        node {
            strapiId
            title
            technologies
            link
            excerpt
            cover{
              localFile{
                childImageSharp {
                  fluid(maxHeight: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
        }
      }
    }
  }
`;

export default Projects;
